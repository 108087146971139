import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const pardon = () => (
  <Layout>
    <SEO title="Sentier et Chemin - Pardon" />
    <h3 className='underline-title'>Pardon</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        TOI devant QUI tout se dénude<br />
        je TE prends à Témoin Seigneur<br />
        Est-il vrai que je l'ai offensé?<br />
        Comment l'ai-je offensé Seigneur?<br />
        Par silence naïf de l'enfant sobre? <br />
        Par souci encastré de Job esseulé? <br />
        Par méditation sanitaire du marabout? <br />
        Par distance fatale du lieu? <br />
        Par solitude du buffle rejeté? <br />
      </p>
      <p>
      <span className="par-starting"></span>S'il est exact Seigneur <br />
      <span className="par-starting"></span>Que je l'ai offensé <br />
      <span className="par-starting"></span>Dis-lui de ma part <br />
      <span className="par-starting"></span>Qu'il me pardonne <br />
      </p>
      <p>
        Que me reproche-t-il Seigneur? <br />
        - mes bras sans repos? <br />
        - mes yeux sans sommeil? <br />
        - ma tete sans chapeau? <br />
        - mon ventre famélique? <br />
        - mon coeur carbonisé? <br />
      </p>
      <p>
      <span className="par-starting"></span>S'il est exact Seigneur <br />
      <span className="par-starting"></span>Que je l'ai offensé <br />
      <span className="par-starting"></span>Je t'en prie Seigneur <br />
      <span className="par-starting"></span>Dis-lui de ma part <br />
      <span className="par-starting"></span>Qu'il me pardonne <br />
      </p>
      <p>
        Ma tête de Kilimandjaro <br />
        Mon regard de vent <br />
        Mes oreilles de varan <br />
        Ma gueule de métèque <br />
        Paraissent le choquer <br />
      </p>
      <p>
      <span className="par-starting"></span>S'il est exact Seigneur <br />
      <span className="par-starting"></span>Que les cadeaux du destin <br />
      <span className="par-starting"></span>L'offusquent réellement <br />
      <span className="par-starting"></span>Je n'en peux rien sincèrement <br />
      <span className="par-starting"></span>Qu'il me pardonne<br />
      </p>
      <p>
        Mais <br />
        Si par avides desseins d'intrigants <br />
        Il s'est laissé entrainer <br />
        Gratuitement <br />
        Qu'il se ressaisisse <br />
        Sincèrement alors <br />
        Tout arbre se tord <br />
        Si peu que ce soit <br />
        Qu'il fasse ses ablutions<br /> 
        Matinales donc <br />
        Lui aussi lui-même <br />
        On ne demande point à nos épaules <br />
        Comme à Centaure de Bernard Dadié <br />
        De porter tous les poids du monde <br />
        Il suffit <br />
        Seulement d'un aveu <br />
        Conscient <span className="font-italic">"mea culpa"</span>
      </p>
      <p>
        Qu'il me pardonne<br />
        Bénis-le Seigneur <br />
        C'est mon frère <br />
        Ton fils <br />
        Je lui demande <br />
        Pardon.<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/sentier-et-chemin-table-des-matieres/">Table des matières</Link>
  </Layout>
)

export default pardon
